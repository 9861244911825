import styled from "@emotion/styled"
import React, { useEffect, useState } from "react"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { useSelector } from "react-redux"
import { VulvaPreloader } from "../../components/builder/Preloader"
import Vulva from "../../components/builder/Vulva"
import ButtonLink from "../../components/form/ButtonLink"
import Layout from "../../components/global/layout"
import { urls } from "../../data/urls"
import { vulvaBuilderCatalog as catalog } from "../../data/vulva-maker"
import Fullscreen from "../../images/utility/fullscreen.svg"

const PageLayout = styled.div`
  opacity: ${props => (props.fullscreen ? "0" : "1")};
  transition: 0.4s;
`
const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: rgba(241, 236, 235, 1);
`
const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 10;
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  padding: ${props => (props.fullscreen ? "0  16px 32px 16px" : "32px 16px")};
  gap: 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
`
const FullscreenButton = styled.button`
  position: absolute;
  left: 16px;
  top: 0;
  bottom: 0;
  margin: auto;
  background: transparent;
  border: none;
  z-index: 10;
  background: url(${Fullscreen});
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  mix-blend-mode: exclusion;
  &:focus {
    outline: none;
  }
`

export default function FinishedBuilderPage() {
  const [fullscreen] = useState(false)
  const [isFullScreenSupported, setIsFullScreenSupported] = useState(false)
  const handle = useFullScreenHandle()
  const state = useSelector(state => state.app.vulva)

  const generateImageMap = (state, catalog) => {
    return Object.entries(state).reduce((acc, [type, index]) => {
      acc[type] = catalog[type].variants[index]
      acc[type].zIndex = catalog[type].zIndex
      return acc
    }, {})
  }

  useEffect(() => {
    setIsFullScreenSupported(
      document.fullscreenEnabled /* Standard syntax */ ||
        document.webkitFullscreenEnabled /* Safari */
    )
  }, [])

  const imageMap = generateImageMap(state, catalog)
  return (
    <Layout
      title={"Make your own vulva"}
      marginless
      leftContainer={
        isFullScreenSupported ? (
          <FullscreenButton onClick={handle.enter} />
        ) : null
      }
    >
      <VulvaPreloader />
      <FullScreen handle={handle}>
        <ImageContainer>
          <Vulva images={imageMap} cover />
        </ImageContainer>
      </FullScreen>
      <PageLayout fullscreen={fullscreen}>
        <ButtonContainer>
          <ButtonLink
            backgroundColor={props => props.theme.rose}
            buttonStyle={"inverted"}
            to={urls.SHARE}
          >
            Finished?
          </ButtonLink>
        </ButtonContainer>
      </PageLayout>
    </Layout>
  )
}
